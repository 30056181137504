@font-face {
    font-family: 'JosefinSans';
    font-style: normal;
    font-weight: 700;
    src: url('/storage/fonts/JosefinSans-Regular.ttf');
}

@font-face {
    font-family: 'JosefinSans';
    font-style: bold;
    font-weight: 900;
    src: url('/storage/fonts/JosefinSans-Bold.ttf');
}

@font-face {
    font-family: 'RopaSans';
    font-style: normal;
    font-weight: 700;
    src: url('/storage/fonts/RopaSans-Regular.ttf');
}

@font-face {
    font-family: 'RopaSans';
    font-style: Bold;
    font-weight: 800;
    src: url('/storage/fonts/RopaSansPro-Bold.ttf');
}

@font-face {
    font-family: 'RopaSans';
    font-style: bolder;
    font-weight: 900;
    src: url('/storage/fonts/RopaSansPro-Black.ttf');
}

@font-face {
    font-family: 'Exo';
    font-style: normal;
    font-weight: 500;
    src: url('/storage/fonts/Exo-VariableFont.ttf');
}
