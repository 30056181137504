@font-face {
  font-family: "JosefinSans";
  font-style: normal;
  font-weight: 700;
  src: url("/storage/fonts/JosefinSans-Regular.ttf");
}
@font-face {
  font-family: "JosefinSans";
  font-style: bold;
  font-weight: 900;
  src: url("/storage/fonts/JosefinSans-Bold.ttf");
}
@font-face {
  font-family: "RopaSans";
  font-style: normal;
  font-weight: 700;
  src: url("/storage/fonts/RopaSans-Regular.ttf");
}
@font-face {
  font-family: "RopaSans";
  font-style: Bold;
  font-weight: 800;
  src: url("/storage/fonts/RopaSansPro-Bold.ttf");
}
@font-face {
  font-family: "RopaSans";
  font-style: bolder;
  font-weight: 900;
  src: url("/storage/fonts/RopaSansPro-Black.ttf");
}
@font-face {
  font-family: "Exo";
  font-style: normal;
  font-weight: 500;
  src: url("/storage/fonts/Exo-VariableFont.ttf");
}
.bg-primary {
  background-color: #B7BF99 !important;
}

.bg-secondary {
  background-color: #697353 !important;
}

.bg-tertiary {
  background-color: #e1ad72 !important;
}

.text-primary {
  color: #B7BF99 !important;
}

.text-secondary {
  color: #697353 !important;
}

.text-tertiary {
  color: #e1ad72 !important;
}

.tertairy-bright {
  color: #d88525;
}

.hover-primary:hover {
  color: #9da873 !important;
}

.link {
  cursor: pointer;
}

body {
  background-color: #697353;
  font-family: "JosefinSans";
}
body .nav {
  position: fixed;
  width: 100%;
  z-index: 50;
  margin-top: -4rem;
}
body .nav .custom-nav-links .nav-link {
  border-right: 1px solid silver;
}
@media (max-width: 768px) {
  body .nav .custom-nav-links .nav-link {
    border-right: none;
  }
}
body .mb-4rem {
  margin-bottom: 4rem;
}
body #addCategory .hide {
  display: none;
}
body #addCategory .show {
  display: block !important;
}
body #addDish .select2-container, body .select2-container, body #addCategory .select2-container {
  width: 100% !important;
}
body #addDish .select2-container--default .select2-selection--single, body .changeDishMain .select2-container--default .select2-selection--single, body #addCategory .select2-container--default .select2-selection--single {
  height: 35.59px !important;
}
body #addDish .select2-container--default .select2-selection--single .select2-selection__arrow, body .changeDishMain .select2-container--default .select2-selection--single .select2-selection__arrow, body #addCategory .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 34.59px !important;
}
body #addDish .select2-container--default .select2-selection--single .select2-selection__rendered, body .changeDishMain .select2-container--default .select2-selection--single .select2-selection__rendered, body #addCategory .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 34.59px !important;
}
body .crud-table {
  background-color: #f8f8f8;
  font-family: sans-serif;
  min-width: min-content;
}
body .crud-table .add-dish {
  height: -webkit-fill-available;
}
body .crud-table .add-category {
  line-height: 14px;
}
body .crud-table .custom-select {
  font-family: sans-serif;
}
body .crud-table .custom-select .optionParent {
  font-weight: bold;
}
body .crud-table .custom-select .optionSubParent {
  padding-left: 15px;
}
body .crud-table .custom-select .optionChild {
  padding-left: 30px;
}
body .crud-table .search_title {
  width: 100%;
  height: 28px;
}
body .crud-table .custom-check {
  height: 66.63px;
  padding-left: 0;
  margin-bottom: 1rem;
}
body .crud-table .custom-check .form-check-input {
  left: 0;
  margin-left: 10px;
  margin-top: 32px;
  height: 25px;
  width: 50px;
}
body .crud-table .pagination {
  display: inline-block;
}
body .crud-table .pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}
body .crud-table .pagination a.active {
  background-color: #4CAF50;
  color: white;
  border-radius: 5px;
}
body .crud-table .pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}
body .crud-table .menu {
  font-weight: bolder;
}
body .crud-table .courses td:first-child {
  padding-left: 10px;
}
body .crud-table .courses td:first-child div {
  transform: rotate(45deg);
  width: fit-content;
  float: left;
  padding-right: 5px;
}
body .crud-table .courses td:first-child div::before {
  content: "\f00c";
  font-family: FontAwesome;
}
body .crud-table .sub_courses td:first-child {
  padding-left: 30px;
}
body .crud-table .sub_courses td:first-child div {
  transform: rotate(45deg);
  width: fit-content;
  float: left;
  padding-right: 5px;
}
body .crud-table .sub_courses td:first-child div::before {
  content: "\f00c";
  font-family: FontAwesome;
}
body .crud-table .disabled {
  background-color: lightgrey !important;
  color: rgba(33, 37, 41, 0.5);
}
body .crud-table .disabled:hover td {
  color: rgba(33, 37, 41, 0.5);
}
body .fa-check {
  color: rgb(0, 158, 0);
}
body .fa-xmark {
  color: red;
}
body .bg-primary {
  text-decoration: none;
}
body .bg-primary:hover {
  background-color: #9da873 !important;
  color: #697353 !important;
}